import React from "react"
import Image from "./Image"
import { useTranslation } from "react-i18next"

import "../styles/WhoWeAre.styl"

export default function WhoWeAre() {
  const { t } = useTranslation()

  return (
    <div className="whoWeAre">
      <h1 className="whoWeAre__title title title--Blue">
        {t("whoWeAre.title")}
      </h1>
      <section className="whoWeAre-hero">
        <div className="hero__image chemical">
          <Image name={"chemical.png"} />
        </div>
        <p className="experiencie text text--Blue text--centered">
          {t("whoWeAre.experience")}
        </p>
        <div className="hero__image pillsglobe">
          <Image name={"pillsglobe.jpg"} />
        </div>
        <p className="suppliers text text--Blue text--centered">
          {t("whoWeAre.suppliers")}
        </p>
      </section>
    </div>
  )
}
