import React from "react"
import SEO from "../components/seo"
import WhoWeAre from "../components/WhoWeAre"
import Header from "../components/Header"

const SecondPage = () => (
  <>
    <SEO title="Page two" />
    <Header />
    <WhoWeAre />
  </>
)

export default SecondPage
